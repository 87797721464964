import { Component } from "react";
import { CartItem } from "../models/cart";

interface OrderProps {
  cartItems: CartItem[]; 
}

export default class OrderIntiation extends Component<OrderProps> {
  render() {
    const { cartItems } = this.props;
    return (
      <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
        <div className="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
          <p className="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">
            Customer’s Cart
          </p>
          {cartItems &&
            cartItems.map((item, i) => {
              return (
                <div className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                  <div className="pb-4 md:pb-8 w-full md:w-40">
                    <img
                      className="w-full hidden md:block"
                      src={process.env.REACT_APP_R2_PUB_URL + item.product.images[0]}
                      alt="dress"
                    />
                  </div>
                  <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                    <div className="w-full flex flex-col justify-start items-start space-y-8">
                      <h3 className="text-xl dark:text-white xl:text-2xl font-semibold leading-6 text-gray-800">
                        {item.product.name}
                      </h3>
                      <div className="flex justify-start items-start flex-col space-y-2">
                        <p className="text-sm dark:text-white leading-none text-gray-800">
                          <span className="dark:text-gray-400 text-gray-300">
                            Style:{" "}
                          </span>{" "}
                        </p>
                        <p className="text-sm dark:text-white leading-none text-gray-800">
                          <span className="dark:text-gray-400 text-gray-300">
                            Size:{" "}
                          </span>{" "}
                        </p>
                        <p className="text-sm dark:text-white leading-none text-gray-800">
                          <span className="dark:text-gray-400 text-gray-300">
                            Brand:{" "}
                          </span>{" "}
                          {/*TO BE DONEitem.color*/}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between space-x-8 items-start w-full">
                      <p className="text-base dark:text-white xl:text-lg leading-6">
                        {item.product.price}
                      </p>
                      <p className="text-base dark:text-white xl:text-lg leading-6 text-gray-800">
                        {item.quantity}
                      </p>
                      <p className="text-base dark:text-white xl:text-lg font-semibold leading-6 text-gray-800">
                        {item.product.price * item.quantity}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        
      </div>
    );
  }
}
