import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileService from "./services/profile";
import { useShop } from "./components/ShopContext";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const VerifyUser = () => {
  const query = useQuery();
  const token = query.get("token");
  const navigate = useNavigate();
  const { setIsLoading } = useShop();
  useEffect(() => {
    const proService: ProfileService = new ProfileService();
    const verifyUser = async () => {
      setIsLoading(true);
      if (token) {
        const response = await proService.VerifyUser(token);
        if (response === "success") {
          toast.success("Verification successfull.", {
            onClose: () => {
              setIsLoading(false);
              navigate("/login");
            },
          });
        } else {
          toast.error("Verification failed", {
            onClose: () => {
              setIsLoading(false);
              navigate("/shop");
            },
          });
        }
      } else {
        toast.error("no token supplied", {
          onClose: () => {
            navigate("/shop");
          },
        });
      }
    };

    verifyUser();
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">
          Verifying user... Please wait...
        </h2>
      </div>
    </div>
  );
};

export default VerifyUser;
