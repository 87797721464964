import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProfileService from "./services/profile";
import ReCAPTCHA from "react-google-recaptcha";

const ResendVerification = () => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    emailOtp: "",
    email: "",
  });
  const navigate = useNavigate();
  const [emailV, setEmailV] = useState(false);

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const proService: ProfileService = new ProfileService();
      if (recaptchaToken) {
        const response = await proService.resendVerification(
          recaptchaToken,
          formData.email
        );
        if (response === "success") {
          await toast.success("Email Sent.. Please check...",{
            onClose:()=>{
                navigate("/shop");
            }
          });
        } else {
          await toast.success("Oops something went wrong.");
        }
      } else {
        toast.error("Please finish captcha");
      }

    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Verify OTP</h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
            onChange={(token: string | null) => setRecaptchaToken(token)}
          />
          <div>
            {recaptchaToken && (
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                Resend Verification
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResendVerification;
