import CONSTANTS from "../const";
import fetchWithAuth from "./fetchInterceptors";

class PaymentService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }
  async createPaymentOrder(amount: number): Promise<string> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          amount: amount * 100,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data.orderId;
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      return "error";
    }
  }
}
export default PaymentService;
