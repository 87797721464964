

import { Product, User } from "./common";

export interface Order {
    id?: number;
    userId?: number;
    status?: OrderStatus;
    totalAmount?: number;
    orderItems?: OrderItem[];
    user?: User;
    createdAt?: Date;
    updatedAt?: Date;
    shippingPrice?:number,
    etd?:string,
    tracking?:string,
    shipping?:Shipping,
    billing?:Billing,
    paymentReference?:string
  }

  export interface Billing {
    id?: number;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    createdAt?: string;  // Use string to represent DateTime in JSON
    updatedAt?: string;  // Use string to represent DateTime in JSON
  }
  
  export interface Shipping {
    id?: number;
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    createdAt?: string;  // Use string to represent DateTime in JSON
    updatedAt?: string;  // Use string to represent DateTime in JSON

  }
  export interface OrderItem {
    id: number;
    orderId: number;
    productId: number;
    quantity: number;
    priceAtOrder: number;
    product: Product;
    order: Order;
  }

  export enum OrderStatus {
    PENDING = "PENDING",
    SHIPPED = "SHIPPED",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
    NEW = "NEW"
  }
  
  export enum ProductStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
  }