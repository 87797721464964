import { CameraIcon, PhotoIcon } from "@heroicons/react/24/outline";
import React from "react";

interface CardProps {
  className?: string;
  imgAlt: string;
  imgSrc?: string;
  productName: string;
  productPrice: number;
  onClick: () => void;
  addToCart: () => void;
}

const Card: React.FC<CardProps> = ({
  className,
  imgAlt,
  imgSrc,
  productName,
  productPrice,
  onClick,
  addToCart,
}) => {
  return (
    <div
      className={`w-64 h-72 md:h-96 bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden cursor-pointer ${className}`}
      onClick={onClick}
    >
      {imgSrc && !imgSrc.endsWith("undefined") ? (
        <img className="w-full h-1/2 object-cover" src={imgSrc} alt={imgAlt} />
      ) : (
        <CameraIcon color="gray"></CameraIcon>
      )}

      <div className="p-4 h-1/2 flex flex-col justify-between">
        <h5 className="text-xs md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
          {productName}
        </h5>
        <div className="flex items-center">
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              className="h-5 w-5 text-yellow-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
          <span className="ml-3 mr-2 rounded bg-cyan-100 px-2.5 py-0.5 text-xs font-semibold text-cyan-800 dark:bg-cyan-200 dark:text-cyan-800">
            5.0
          </span>
        </div>
        <div className="flex items-center justify-between mt-2">
          <span className="text-sm md:text-xl font-bold text-gray-900 dark:text-white">
            ₹ {productPrice}
          </span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              addToCart();
            }}
            className="rounded-lg bg-cyan-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
