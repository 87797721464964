import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import { NavigateFunction } from "react-router-dom";
import { Helper } from "../util/helper";
import { CartItem } from "../models/cart";
import CONSTANTS from "../const";

interface CartProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  products: CartItem[] | null;
  updateToCart: (productId: number, quantity: number) => void;
  navigate: NavigateFunction;
}

export default class Cart extends Component<CartProps> {
  render() {
    const productPage = `${process.env.REACT_APP_OWN_DOMAIN}/product/`;
    const { open, setOpen, products, navigate, updateToCart } = this.props;
    const handleRemove = async (productId: number, quantity: number) => {
      await updateToCart(productId, quantity);
    };

    const getTotalAmount = () => {
      return Helper.getTotalAmount(products!);
    };
    return (
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-lg font-medium text-gray-900">
                            Shopping cart
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="mt-8">
                          <div className="flow-root">
                            <ul className="-my-6 divide-y divide-gray-200">
                              {products &&
                                products.map((product) => (
                                  <li
                                    key={product.productId}
                                    className="flex py-6"
                                  >
                                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                      <img
                                        src={process.env.REACT_APP_R2_PUB_URL + product.product.images[0]}
                                        alt={product.product.name}
                                        className="h-full w-full object-cover object-center"
                                      />
                                    </div>

                                    <div className="ml-4 flex flex-1 flex-col">
                                      <div>
                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                          <h3>
                                            <a
                                              href={
                                                productPage + product.productId
                                              }
                                            >
                                              {product.product.name}
                                            </a>
                                          </h3>
                                          <p className="ml-4">
                                            {product.product.price * product.quantity}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-1 items-end justify-between text-sm">
                                        <p className="text-gray-500">Qty</p>
                                        <span
                                          className="cursor-pointer hover:text-blue-500"
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            await updateToCart(
                                              product.productId,
                                              product.quantity + 1
                                            );
                                          }}
                                        >
                                          +
                                        </span>
                                        <p className="text-gray-500">
                                          {product.quantity}
                                        </p>
                                        <span
                                          className="cursor-pointer hover:text-blue-500"
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            await updateToCart(
                                              product.productId,
                                              product.quantity - 1
                                            );
                                          }}
                                        >
                                          -
                                        </span>
                                        
                                        <div className="flex">
                                          <button
                                            onClick={async (e) => {
                                              e.preventDefault();
                                              await updateToCart(
                                                product.productId,
                                                0
                                              );
                                            }}
                                            type="button"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>INR {getTotalAmount()}</p>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          Shipping and taxes calculated at checkout.
                        </p>
                        <div className="mt-6">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setOpen(false)
                              navigate("order");

                            }}
                            className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                          >
                            Checkout
                          </a>
                        </div>
                        <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                          <p>
                            or{" "}
                            <button
                              type="button"
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              Continue Shopping
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}
