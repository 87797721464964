import { Bars3Icon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Card from "./components/Card";
import { useShop } from "./components/ShopContext";
import SideBar from "./components/SideBar";
import SideBarCollapsible from "./components/SideBarCollapsible";
import SideBarItem from "./components/SideBarItem";
import CartSkeleton from "./components/skeletonCart";
import { Product } from "./models/common";
import ProductModal from "./ProductModal";
import GeneralService, { Category } from "./services/general";

function Shop() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>();
  const [displayProducts, setDisplayProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartItems, UpdateToCart } = useShop();
  const [filtering, setFiltering] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<{
    category: number;
    subcategory: number;
  }>({ category: -1, subcategory: -1 });

  const addToCart = (product: Product) => {
    const cartItem = cartItems?.find(
      (item) => item.productId.toString() === product.id.toString()
    );
    const quantity = cartItem?.quantity ?? 0;
    UpdateToCart(product.id, quantity + 1);
  };

  useEffect(() => {
    setFiltering(true);
    const fetchData = async () => {
      try {
        const generalService = new GeneralService();
        const categoriesData = await generalService.getCategories();
        const productsData = await generalService.getProducts();
        setCategories(categoriesData);
        setProducts(productsData);
        setDisplayProducts(productsData);
        setFiltering(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setFiltering(true);

    const filteredProducts =
      selectedCategory?.category != -1
        ? products?.filter((product) => {
            const catMatch = product.categoryId == selectedCategory?.category;
            const subMatch =
              selectedCategory?.subcategory != -1
                ? product.subCategoryId == selectedCategory?.subcategory
                : true;
            return catMatch && subMatch;
          })
        : products;
    setDisplayProducts(filteredProducts);
    setFiltering(false);
  }, [selectedCategory]);

  const openModal = (product: Product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="block md:flex md:h-[900px]">
        <div className="block md:hidden w-full bg-yellow-400 h-10 p-2">
          <Bars3Icon
            className="h-6 w-6"
            color="white"
            onClick={(e) => {
              e.preventDefault();
              setSideMenuOpen(!sideMenuOpen);
            }}
          />
        </div>
        <SideBar className={`${!sideMenuOpen ? "hidden" : ""} md:block`}>
          <SideBarItem
            menuOnClick={() => {
              setSelectedCategory({
                category: -1,
                subcategory: -1,
              });
            }}
            className="bg-yellow-300"
            key="-1"
            title="All"
          ></SideBarItem>
          {categories &&
            categories.map((category,i) =>
              category.Subcategories.length > 0 ? (
                <SideBarCollapsible
                key={i}
                  className="bg-yellow-300"
                  title={category.name}
                >
                  <SideBarItem
                    menuOnClick={() => {
                      setSelectedCategory({
                        category: category.id,
                        subcategory: -1,
                      });
                    }}
                    
                    className="bg-yellow-400"
                    innerButtonClassName="ml-3"
                    title="All"
                  ></SideBarItem>
                  {category.Subcategories.map((sub,i) => (
                    <SideBarItem
                    key={i}
                      menuOnClick={() => {
                        setSelectedCategory({
                          category: category.id,
                          subcategory: sub.id,
                        });
                      }}
                      className="bg-yellow-400"
                      innerButtonClassName="ml-3"
                      title={`${sub.name}`}
                    ></SideBarItem>
                  ))}
                </SideBarCollapsible>
              ) : (
                //render collapse
                //render non collapse
                <SideBarItem
                key={i}
                  className="bg-yellow-300"
                  menuOnClick={() => {
                    setSelectedCategory({
                      category: category.id,
                      subcategory: -1,
                    });
                  }}
                  title={category.name}
                ></SideBarItem>
              )
            )}
        </SideBar>
        <div className="px-2 py-2 justify-center bg-stone-200 w-full flex flex-wrap gap-4 overflow-scroll">
          {!filtering ? (
            displayProducts && displayProducts.length > 0 ? (
              displayProducts.map((product, i) => (
                <Card
                  key={i}
                  className="md:w-48 w-[9.5rem]"
                  imgAlt={product.name}
                  imgSrc={`${product.images && process.env.REACT_APP_R2_PUB_URL}${product.images[0]}`}
                  productName={product.name}
                  productPrice={product.price}
                  onClick={() => openModal(product)}
                  addToCart={() => addToCart(product)}
                />
              ))
            ) : (
              <span>No products found</span>
            )
          ) : (
            [...Array(12)].map((_, i) => <CartSkeleton className="md:w-48 w-[9.5rem]" key={i} />)
          )}
        </div>
      </div>
      <ProductModal
        product={selectedProduct}
        show={isModalOpen}
        onClose={closeModal}
        addToCart={addToCart}
      />
    </div>
  );
}

export default Shop;
