"use server";

import CONSTANTS from "../const";
import {
  AddToCartRequest,
  AddToCartResponse,
  RetrieveCartResponse,
} from "../models/cart";
import fetchWithAuth from "./fetchInterceptors";

class CartService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  async GetCart(userId: string): Promise<RetrieveCartResponse | { error: string }> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/cart/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch cart");
      }

      const data: RetrieveCartResponse = await response.json();
      return data;
    } catch (error: any) {
      console.error("Error fetching cart:", error);
      return { error: error.message || "Failed to fetch cart" };
    }
  }

  async addCartItem(request: AddToCartRequest): Promise<AddToCartResponse | { error: string }> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/cart/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(request),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add cart item");
      }

      const data: AddToCartResponse = await response.json();
      return data;
    } catch (error: any) {
      console.error("Error adding cart item:", error);
      return { error: error.message || "Failed to add cart item" };
    }
  }
}

export default CartService;
