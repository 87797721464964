import React, { useEffect } from "react";
import { Button, Card, Carousel } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import carousel1 from "./assets/slide1.png";
import carousel2 from "./assets/slide2.png";
import carousel3 from "./assets/slide3.png";
import carousel4 from "./assets/slide4.png";
import carousel5 from "./assets/slide5.png";
import styles from "./home.module.css";

type HomePageProduct = {
  name: string;
  description: string;
  imageUrl: string;
};

function Home() {
  const HomePageProducts: HomePageProduct[] = [
    {
      description:
        "The world's first professional freshwater aquascape dosing system, also suitable for marine saltwater tank.",
      imageUrl: "./assets/card2.webp",
      name: "Chihiros Dosing Pump System",
    },
    {
      description:
        "RGB VIVID II 10th Edition LED Light: 3 in 1 RGB LEDs, APP controllable, 3 colors (Red, Green, and Blue) adjustable.",
      imageUrl: "./assets/card1.webp",
      name: "Chihiros RGB VIVID II 10th Edition LED Light",
    },
    {
      description:
        "Chihiros Doctor is the newest Doctor and is the fourth generation rated for tanks larger than 125L (45-250cm tank).",
      imageUrl: "./assets/card3.webp",
      name: "Chihiros Doctor (Bluetooth Control) 125L+",
    },
    {
      description:
        "Chihiros magnet cleaner is another craft of Chihiros product line. Made of the highest performance magnets-N52 in the magnet industry, the cleaner feels more comfortable.",
      imageUrl: "./assets/card4.webp",
      name: "Chihiros Magnet Cleaner",
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    // Add animation if user hasn't opted for reduced motion
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      const scrollers = document.querySelectorAll(".scroller");
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", "true");
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner!.children);
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true) as HTMLElement;
          duplicatedItem && duplicatedItem.setAttribute("aria-hidden", "true");
          scrollerInner!.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div>
      <div className="bg-slate-800" style={{ height: "300px" }}>
        <Carousel slide={true} className="custom-carousel">
          <img src={carousel1} alt="Slide 1" />
          <img src={carousel2} alt="Slide 2" />
          <img src={carousel3} alt="Slide 3" />
          <img src={carousel4} alt="Slide 4" />
          <img src={carousel5} alt="Slide 5" />
        </Carousel>
      </div>
      <div className="p-4 w-full justify-center flex md:justify-between">
        <Button
          gradientMonochrome="cyan"
          className="w-60"
          onClick={() => navigate("/shop")}
        >
          <span className="text-2xl">Shop now</span>
        </Button>
        {/* Additional content here */}
      </div>
      <div className="flex flex-wrap gap-4 justify-center w-full bg-yellow-400 rounded-xl px-4 py-4">
        {HomePageProducts.map((p, i) => (
          <Card
            className="max-w-[9rem] md:max-w-xs"
            key={i}
            imgSrc={require(`${p.imageUrl}`)}
          >
            <h5 className="text-xs md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {p.name}
            </h5>
            <p className="hidden md:block font-normal text-gray-700 dark:text-gray-400">
              {p.description}
            </p>
          </Card>
        ))}
      </div>
      <div className="w-full flex items-center justify-center mt-5">
        <div className="content-center text-center">
          <div className="text-lg md:text-2xl">Brands we do</div>
          <div
            className={styles.scroller}
            data-direction="right"
            data-speed="slow"
            data-animated="true"
          >
            <div className={styles.scroller__inner}>
              <img
                src={require("./assets/ada.webp")}
                className="aspect-square object-contain w-40"
                alt="ADA"
              />
              <img
                src={require("./assets/chihiros-india.png")}
                className="aspect-square object-contain w-40"
                alt="Chihiros India"
              />
              <img
                src={require("./assets/dooa.png")}
                className="aspect-square object-contain w-40"
                alt="DOOA"
              />
              <img
                src={require("./assets/intan.png")}
                className="aspect-square object-contain w-40"
                alt="Intan"
              />
              <img
                src={require("./assets/idid.png")}
                className="aspect-square object-contain w-40"
                alt="IDID"
              />
              <img
                src={require("./assets/api.png")}
                className="aspect-square object-contain w-40"
                alt="API"
              />
            </div>
          </div>
          <div  className={styles.scroller}
            data-direction="left"
            data-speed="slow"
            data-animated="true">
          <ul className={`${styles.scroller__inner} ${styles.tag_list}`}>
            <li>Chihiros Vivid II Black </li>
            <li>Chihiros 21 cm Curved Scissors </li>
            <li>Chihiros 21 cm Straight Scissors</li>
            <li>Chihiros 21 cm Wavy Scissors</li>
            <li>Chihiros 25 cm Curved Tweezers</li>
            <li>Chihiros 33 cm Straight Tweezers</li>
            <li>Chihiros 33 cm Curved Tweezers</li>
            <li>Chihiros 25 cms Straight Tweezers</li>
            <li>Chihiros 70 x 140cm Wiping Towel </li>
            <li>Chihiros B120 with App Control</li>
            <li>Chihiros B30 with App Control</li>
            <li>Chihiros B45 with App Control</li>
            <li>Chihirio B60 with App Control</li>
            <li>Chihiros B90 with App Control</li>
            <li>Chihiros Base for Series II RGB</li>
            <li>Chihiros Blades for Scraping Algae Knife</li>
            <li>Chihiros Stand Broad for Vivid</li>
            <li>Chihiros Aqua Soil 9ltr Pack of 3</li>
            <li>Chihiros C Series II RGB</li>
            <li>Chihiros C Series II White</li>
            <li>Chihiros Clean Hose 12 x 16</li>
            <li>Chihiros Clean Hose 16 x 22</li>
            <li>Chihiros 9 x 12 Clear Hose</li>
            <li>Chihiros Cooling Fan with Bluetooth</li>
            <li>Chihiros Doctor Mate </li>
            <li>Chihiros Dosing Container</li>
            <li>Chihiros Dosing Pump 4 Head ( Whole Set)</li>
            <li>Chihiros Dosing Pump Mate 2 Head (Whole Set)</li>
            <li>Chihiros Garden Mat 30x30cm</li>
            <li>Chihiros Garden Mat 60x30cm</li>
            <li>Chihiros Glass Pot for Plant</li>
            <li>Chihiros Hanging Rope Kit RGB Vivid Mini</li>
            <li>Chihiros Hanging Rope Kit Slim</li>
            <li>Chihiros WRGB 2 Hanging Rope Kit</li>
            <li>Chihiros WRGB II Pro Hanging Rope Kit</li>
            <li>Chihiros Nano Co2 Diffuser Large</li>
            <li>Chihiros U Clip Large </li>
            <li>Chihiros Magnet Cleaner Mini</li>
            <li>Chihiros Vivid Il Black </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
