import React from 'react';
import { Modal, Button } from 'flowbite-react';
import { Product } from './models/common';
import CONSTANTS from './const';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

interface ProductModalProps {
  product: Product | null;
  show: boolean;
  onClose: () => void;
  addToCart: (product: Product) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ product, show, onClose, addToCart }) => {
  if (!product) return null;

  return (
    <Modal show={show} size="6xl" popup={true} onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="flex">
          {/* Carousel */}
          <div className="w-1/2 pr-4">
            <Carousel
              showThumbs={true}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              autoPlay={true}
              dynamicHeight={true}
              className="w-full h-auto object-cover rounded-lg"
            >
              {product.images && product.images.map((image, index) => (
                <div key={index}>
                  <img src={`${process.env.REACT_APP_R2_PUB_URL}${image}`} alt={`Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>

          {/* Product Details */}
          <div className="w-1/2">
            <div className="mb-4">
              <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
              <p className="text-xl text-gray-700 mb-2">${product.price.toFixed(2)}</p>
              <p className="text-lg text-gray-600 mb-2">Stock: {product.stock}</p>
              <p className="text-lg text-gray-600 mb-4">Brand: {product.brand}</p>
              <Button onClick={() => addToCart(product)} className="mb-4">
                Add to Cart
              </Button>
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="mt-4">
          <h2 className="text-2xl font-bold mb-2">Description</h2>
          <div className="text-gray-700" dangerouslySetInnerHTML={{__html:product.description}}></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
