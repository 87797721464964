"use server";
import CONSTANTS from "../const";
import fetchWithAuth from "./fetchInterceptors";

export interface CreateNotificationRequest {
  n_msg: string;
  userId: number;
}

export interface CreateNotificationResponse {
  id: number;
  n_msg: string;
  userId: number;
  created_at: string;
  read_at: string | null;
}

class NotificationService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  createNotification = async (request: CreateNotificationRequest): Promise<CreateNotificationResponse> => {
    const response = await fetchWithAuth(`${this.baseURL}/notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      throw new Error('Failed to create notification');
    }

    return response.json();
  }

  markNotificationAsRead = async (id: number): Promise<CreateNotificationResponse> => {
    const response = await fetchWithAuth(`${this.baseURL}/notifications/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ read_at: new Date() }),
    });

    if (!response.ok) {
      throw new Error('Failed to mark notification as read');
    }

    return response.json();
  }

  getNotifications = async (userId: number): Promise<CreateNotificationResponse[]> => {
    const response = await fetchWithAuth(`${this.baseURL}/notifications?userId=${userId}`);

    if (!response.ok) {
      throw new Error('Failed to fetch notifications');
    }

    return response.json();
  }
}

export default NotificationService;
