import React from "react";
import { Order } from "../models/order";

type DisplayOrderProps = {
  order: Order;
  onClose: () => void;
  onCancel: (orderId: number) => void;
  downloadBill: (orderId: number) => void;
};

const DisplayOrder = ({
  order,
  onClose,
  onCancel,
  downloadBill,
}: DisplayOrderProps) => {
  const [updatedOrder, setUpdatedOrder] = React.useState(order);

  if (!order) return null;

  const handleCancel = () => {
    onCancel(order.id!);
  };

  const handleDownloadBill = () => {
    downloadBill(order.id!);
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-h-[90vh] overflow-y-auto">
        <button onClick={onClose} className="float-right text-red-500">
          Close
        </button>
        <h2 className="text-2xl font-bold mb-4">Order Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-700 font-semibold">
              Order ID:
            </label>
            <span className="block text-gray-900">{order.id}</span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              User ID:
            </label>
            <span className="block text-gray-900">{order.userId}</span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Total Amount:
            </label>
            <span className="block text-gray-900">
              ₹{order.totalAmount?.toFixed(2)}
            </span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Created At:
            </label>
            <span className="block text-gray-900">
              {new Date(order.createdAt!).toLocaleDateString()}
            </span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Updated At:
            </label>
            <span className="block text-gray-900">
              {new Date(order.updatedAt!).toLocaleDateString()}
            </span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Shipping ID:
            </label>
            <span className="block text-gray-900">{order.shipping?.id}</span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Billing ID:
            </label>
            <span className="block text-gray-900">{order.billing?.id}</span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Shipping Price:
            </label>
            <span className="block text-gray-900">
              ₹{order.shippingPrice!.toFixed(2)}
            </span>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Payment Reference:
            </label>
            <span className="block text-gray-900">
              {order.paymentReference || "N/A"}
            </span>
          </div>
        </div>

        <h3 className="text-xl font-bold mb-4">Order Items</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">Item ID</th>
                <th className="py-2 px-4">Product</th>
                <th className="py-2 px-4">Quantity</th>
                <th className="py-2 px-4">Price at Order</th>
              </tr>
            </thead>
            <tbody>
              {order.orderItems &&
                order.orderItems.map((item) => (
                  <tr key={item.id} className="bg-gray-100">
                    <td className="py-2 px-4 text-center">{item.id}</td>
                    <td className="py-2 px-4 text-center">
                      {item.product.name}
                    </td>
                    <td className="py-2 px-4 text-center">{item.quantity}</td>
                    <td className="py-2 px-4 text-center">
                      ₹{item.priceAtOrder.toFixed(2)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-between">
          {order.status === "PENDING" && (
            <button
              onClick={handleCancel}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            >
              Cancel
            </button>
          )}
          {(order.status === "SHIPPED" || order.status === "DELIVERED") && (
            <button
              onClick={handleDownloadBill}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            >
              Download Bill
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayOrder;
