"use server";

import CONSTANTS from "../const";
import { Product } from "../models/common";

export interface Category {
  id: number;
  name: string;
  Subcategories: {
    id: number;
    name: string;
  }[];
}

class GeneralService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  async getCategories(): Promise<Category[]> {
    try {
      const response = await fetch(`${this.baseURL}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          // Include the authorization header if needed
        },
        credentials: "include",
        //credentials: 'include', // Ensure cookies are sent with the request
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }

      const data: Category[] = await response.json();
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  async getProducts(): Promise<Product[]> {
    try {
      const response = await fetch(`${this.baseURL}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          // Include the authorization header if needed
        },
        credentials: "include",
        //credentials: 'include', // Ensure cookies are sent with the request
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }

      const data: Product[] = await response.json();
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  async searchProducts(searchQuery:string): Promise<Product[]> {
    try {
      const response = await fetch(`${this.baseURL}/products/search?query=${searchQuery}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",

          // Include the authorization header if needed
        },
        credentials: "include",
        //credentials: 'include', // Ensure cookies are sent with the request
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }

      const data: Product[] = await response.json();
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}


export default GeneralService;
