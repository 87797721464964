"use server";

import CONSTANTS from "../const";
import { getCookie } from "../util/cookie";
import fetchWithAuth from "./fetchInterceptors";
import { UserResponse } from "./profile";

export interface LoginResponse {
  
    sessionToken:string,
    jwt:string,
    success:boolean
  }

class LoginService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  async login(email: string, password: string,token:string): Promise<LoginResponse> {
    try {
      const response = await fetch(`${this.baseURL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password,token }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);      }

      const data: LoginResponse = await response.json();
      return data;
    } catch (error: any) {
      
      console.error(error);
      throw error;
    }
  }

  async getUserDetails(sessionToken: string): Promise<UserResponse> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/users/details`, {
        credentials:"include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }

      const data: UserResponse = await response.json();
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }
}

export default LoginService;
