"use server";

import { Shipping } from "../models/order";
import fetchWithAuth from "./fetchInterceptors";

export interface UserResponse {
  success: boolean;
  userDetails: BasicUser;
}

export interface BasicUser {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
}

export interface VerifyOtpResponse {
  emailVerified: boolean;
}

export interface RegsiterForm {
  email: string;
  password: string;
  name: string;
  address: string;
  phone: string;
  countryCode: string;
  verifyPassword?: string;  
}

class ProfileService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  async Register(params: RegsiterForm,token:string): Promise<string> {
    const response = await fetch(`${this.baseURL}/users/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({params,token}),
    });

    if (response.status === 201) {
      return "success";
    }
    return "fail";
  }
  async GetAddressCollection(): Promise<Shipping[]> {
    try {
      const response = await fetchWithAuth(
        `${this.baseURL}/users/relatedAddresses`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      return data.addresses;
    } catch (error: any) {
      throw error;
    }
  }

  async ForgotPassword(request: any) {
    try {
      console.log(request);
      const response = await fetch(`${this.baseURL}/users/forgotpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
    } catch (error: any) {
      throw error;
    }
  }
  async ResetPassword(request: any): Promise<string> {
    try {
      const response = await fetch(`${this.baseURL}/users/resetpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        return "success";
      } else {
        const data = await response.json();
        return data.message;
      }

      return "fail";
    } catch (error: any) {
      throw error;
    }
  }
  async VerifyOTP(request: any): Promise<VerifyOtpResponse> {
    try {
      const response = await fetch(`${this.baseURL}/users/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const verifyResponse: VerifyOtpResponse = await response.json();
      return verifyResponse;
    } catch (error: any) {
      throw error;
    }
  }
  
  async VerifyUser(token:string): Promise<string> {
    try {
      const response = await fetch(`${this.baseURL}/users/verifyuser/${token}`)
      if(response.ok)
      {
        return "success"
      }
      else
      {
        const data = await response.json();
        return data.error;
      }      
    } catch (error: any) {
      throw error;
    }
  }
  
  async resendVerification(token:string,email:string): Promise<string> {
    try {
      const response = await fetch(`${this.baseURL}/users/resendverification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({token,email}),
        }
      )
      if(response.ok)
      {
        return "success"
      }
      else
      {
        const data = await response.json();
        return data.error;
      }      
    } catch (error: any) {
      throw error;
    }
  }
}

export default ProfileService;
