import { useEffect, useState } from "react";
import OrderService from "./services/order";
import { Order, OrderStatus } from "./models/order";
import CONSTANTS from "./const";
import DisplayOrder from "./components/DisplayOrder";
import { toast } from "react-toastify";

const OrdersList = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const oService: OrderService = new OrderService();
        const response = await oService.getUserOrders();
        setOrders(response);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  const downloadBill = async (orderId: number) => {
    const oService: OrderService = new OrderService();
    const res = await oService.downloadBill(orderId);
    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `bill_${orderId}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };
  const cancelOrder = async (orderId: number) => {
    try {
      const oService: OrderService = new OrderService();
      const response = await oService.cancelUserOrders(orderId);
      if (response.order) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId
              ? { ...order, status: OrderStatus.CANCELLED }
              : order
          )
        );
        toast.success("Order Cancelled");
      }
      else
      {
        toast.error(response.message);
      }
      
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const handleOrderClick = async (orderId: number) => {
    // Fetch detailed data for the selected order
    try {
      const oService: OrderService = new OrderService();
      const response = await oService.getOrder(orderId);
      setSelectedOrder(response!);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900">My Orders</h1>
      <div className="mt-8 space-y-4">
        {orders.map((order) => (
          <div
            key={order.id}
            className="flex items-center p-4 bg-white rounded-lg shadow"
          >
            <div className="w-1/4">
              {order.orderItems![0].product.images[0] && (
                <img
                  src={`${process.env.REACT_APP_R2_PUB_URL}${
                    order.orderItems![0].product.images[0]
                  }`}
                  alt={order.orderItems![0].product.name}
                  width={100}
                  height={100}
                  className="object-cover rounded-lg"
                />
              )}
            </div>
            <div className="w-2/4 pl-4">
              <p
                className="text-lg font-medium text-blue-700 cursor-pointer"
                onClick={() => handleOrderClick(order.id!)}
              >
                Order #{order.id}
              </p>
              <p className="text-sm text-gray-600">
                Created on: {new Date(order.createdAt!).toLocaleDateString()}
              </p>
              <p className="text-sm text-gray-600">Status: {order.status}</p>
              {order.tracking && (
                <p className="text-sm text-gray-600">
                  Tracking: {order.tracking}
                </p>
              )}
            </div>
            <div className="w-1/4 text-right">
              {order.status === "PENDING" && (
                <button
                  onClick={() => cancelOrder(order.id!)}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  Cancel
                </button>
              )}
              {(order.status === "SHIPPED" || order.status === "DELIVERED") && (
                <button
                  onClick={() => downloadBill(order.id!)}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  Download Bill
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {selectedOrder && (
        <DisplayOrder
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          onCancel={cancelOrder}
          downloadBill={downloadBill}
        />
      )}
    </div>
  );
};

export default OrdersList;
