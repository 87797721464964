import React, { useEffect, useState } from "react";
import { useShop } from "./components/ShopContext";
import { Helper } from "./util/helper";
import { Order as OrderInfo, Shipping } from "./models/order";
import OrderIntiation from "./components/OrderInitiation";
import AddressCollection from "./components/AddressCollection";
import OrderService, { ShippingResponse } from "./services/order";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileService from "./services/profile";

interface NavBtnSetting {
  previousBtnText: string;
  nextBtnText: string;
  currentScreen: string;
}

const navRoute = {
  1: {
    previousBtnText: "",
    nextBtnText: "Shipping Address",
    currentScreen: "Order Details",
  },
  2: {
    previousBtnText: "Order Details",
    nextBtnText: "Billing Address",
    currentScreen: "Shipping Address",
  },
  3: {
    previousBtnText: "Shipping Address",
    nextBtnText: "Place Order",
    currentScreen: "Billing Address",
  },
};

function Order() {
  const { cartItems, user } = useShop();
  const [currentNav, setCurrentNav] = useState(1);
  const [navBtn, setNavBtn] = useState<NavBtnSetting>(navRoute[1]);
  const [sameAsShipping, setSameAsShipping] = useState(false);
  const [addressCollection, setAddressCollection] = useState<Shipping[]>();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<OrderInfo>({
    shippingPrice: 0,
    totalAmount: 0,
    orderItems: [],
    shipping: {
      address: "",
      city: "Chennai",
      country: "India",
      state: "Tamil Nadu",
    },
    billing: {
      address: "",
      city: "Chennai",
      country: "India",
      state: "Tamil Nadu",
    },
  });

  const handleOrderPrev = () => {
    if (currentNav === 2) {
      setNavBtn(navRoute[1]);
      setCurrentNav(1);
    }
    if (currentNav === 3) {
      setNavBtn(navRoute[2]);
      setCurrentNav(2);
    }
  };

  const handleOrderNext = async () => {
    if (currentNav === 1) {
      setNavBtn(navRoute[2]);
      setCurrentNav(2);
    }
    if (currentNav === 2) {
      if (orderDetails.shipping?.postalCode !== "") {
        const orderService = new OrderService();
        const response: ShippingResponse = await orderService.getShippingRate(
          orderDetails.shipping?.postalCode!,
          "600037",
          2
        );
        if (response) {
          setOrderDetails((prevOrderDetails) => ({
            ...prevOrderDetails,
            shippingPrice: response.rate,
            etd: response.etd,
          }));
        }
      }
      if (sameAsShipping) {
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          billing: { ...prevDetails.shipping },
        }));
      } else {
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          billing: {
            address: "",
            city: "Chennai",
            country: "India",
            state: "Tamil Nadu",
          },
        }));
      }
      setNavBtn(navRoute[3]);
      setCurrentNav(3);
    }
    if (currentNav === 3) {
      const orderService: OrderService = new OrderService();
      if (
        user &&
        user.id > 0 &&
        orderDetails.shipping &&
        orderDetails.billing
      ) {
        const createOrderResponse = await orderService.createOrder(
          user?.id,
          orderDetails.shipping,
          orderDetails.billing,
          orderDetails.shippingPrice ? orderDetails.shippingPrice : 0,
          orderDetails.etd ? orderDetails.etd : "N/A"
        );
        if (typeof createOrderResponse === "string") {
          toast.error("Order Creation Failed");
        } else if ("id" in createOrderResponse) {
          navigate(`/ordersummary/${createOrderResponse.id}/order`);
        }
      }
    }
  };

  useEffect(() => {
    const updatedOrder = {
      ...orderDetails,
      totalAmount: Helper.getTotalAmount(cartItems!),
    };
    setOrderDetails(updatedOrder);
  }, [cartItems, orderDetails.shippingPrice]);

  useEffect(() => {
    const profileService: ProfileService = new ProfileService();

    const fetchAddresses = async () => {
      const addresses: Shipping[] = await profileService.GetAddressCollection();

      setAddressCollection(addresses);
    };
    fetchAddresses();
  }, []);

  const handleShippingAddressChange = (address: Shipping) => {
    setOrderDetails({
      ...orderDetails,
      shipping: address,
    });
  };

  const handleBillingAddressChange = (address: Shipping) => {
    setOrderDetails({
      ...orderDetails,
      billing: address,
    });
  };
  return (
    orderDetails && (
      <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className="flex justify-start item-start space-y-2 flex-col">
          <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
            New Order
          </h1>
          <p className="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">
            {Helper.formatDateTime()}
          </p>
        </div>
        <div className="flex justify-start item-start space-y-2 flex-col mt-4">
          <h1 className="text-lg dark:text-white lg:text-2xl font-semibold leading-7 lg:leading-9 text-gray-800">
            {navBtn.currentScreen}
          </h1>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            {currentNav === 1 && <OrderIntiation cartItems={cartItems!} />}
            {currentNav === 2 && (
              <div className="w-full">
                <div className="flex items-center mb-4">
                  <input
                    id="checkbox-2"
                    type="checkbox"
                    checked={sameAsShipping}
                    onChange={(e) => {
                      setSameAsShipping(e.target.checked);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="checkbox-2"
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Use same for billing
                  </label>
                </div>

                <AddressCollection
                  selectedAddress={orderDetails.shipping!}
                  onAddressChange={handleShippingAddressChange}
                  shippingList={addressCollection!}
                />
              </div>
            )}
            {currentNav === 3 && (
              <div className="w-full">
                <AddressCollection
                  selectedAddress={orderDetails.billing!}
                  onAddressChange={handleBillingAddressChange}
                  shippingList={addressCollection!}
                />
              </div>
            )}
            <div className="flex justify-center flex-col md:flex-row items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="w-full flex justify-center items-center">
                <button
                  disabled={navBtn.previousBtnText === ""}
                  onClick={() => {
                    handleOrderPrev();
                  }}
                  className="hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                >
                  &lt; Prev : {navBtn.previousBtnText}
                </button>
              </div>
              <div className="w-full flex justify-center items-center">
                <button
                  onClick={() => {
                    handleOrderNext();
                  }}
                  className="hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                >
                  Next : {navBtn.nextBtnText} &gt;
                </button>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-4 md:p-6 xl:p-4 flex-col">
            <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
              Payment Summary
            </h3>
            <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 mt-6">
              <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                <div className="flex justify-between w-full mt-3">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Subtotal
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    ₹{" "}
                    {cartItems?.reduce(
                      (total, item) =>
                        total + item.product.price * item.quantity,
                      0
                    ) ?? 0}
                  </p>
                </div>
                <div className="flex justify-between w-full  mt-4">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Discount
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    ₹ 0
                  </p>
                </div>
                <div className="flex justify-between w-full mt-4">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Shipping
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    ₹ {orderDetails.shippingPrice}
                  </p>
                </div>
                <div className="flex justify-between w-full mt-4">
                  <p className="text-base dark:text-white leading-4 text-gray-800">
                    Estimated delivery date
                  </p>
                  <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                    ₹ {orderDetails.etd}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-4">
                <p className="text-base dark:text-white font-semibold leading-4 text-gray-800">
                  Total
                </p>
                <p className="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">
                  ₹ {orderDetails.totalAmount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Order;
