import React, { ReactNode } from "react";

interface SidebarProps {  
  children: ReactNode;
  className:string;
}

const SideBar: React.FC<SidebarProps> = ({ children,className }) => {
  return (
    <div className="md:max-w-2xl bg-gray-800">
      <aside className={`md:w-64 ${className}`} aria-label="Sidebar">
        <div className="overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
          <ul className="">{children}</ul>
        </div>
      </aside>      
    </div>
  );
};

export default SideBar;