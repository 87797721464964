import React, { useState, useEffect } from "react";
import { LookUp } from "../util/lookup";
import { Shipping } from "../models/order";

interface AddressProps {
  selectedAddress: Shipping;
  onAddressChange: (address: Shipping) => void;
  shippingList: Shipping[]; // Assume this prop is provided by the parent
}

const AddressCollection: React.FC<AddressProps> = ({
  selectedAddress,
  onAddressChange,
  shippingList,
}) => {
  const [cities, setCities] = useState<string[]>([]);

  const handleFormChange = (e: any) => {
    onAddressChange({
      ...selectedAddress,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const stateData = LookUp.stateData.find(
      (stateItem) => stateItem.State === selectedAddress.state
    );
    if (stateData) {
      setCities(stateData.Cities);
      const initialCity = stateData.Cities[0];
      onAddressChange({
        ...selectedAddress,
        city: initialCity,
      }); // Notify parent of initial city change
    } else {
      setCities([]);
      onAddressChange({
        ...selectedAddress,
        city: "",
      }); // Notify parent if no cities
    }
  }, [selectedAddress.state]);

  const handleShippingSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(e.target.value);
    const selectedShipping = shippingList.find((shipping) => shipping.id === selectedId);
    if (selectedShipping) {
      onAddressChange(selectedShipping);
    }
  };

  return (
    <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
      <div className="w-6/12">
        <label
          htmlFor="shipping-select"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Select Existing Address:
        </label>
        <select
          id="shipping-select"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={handleShippingSelect}
        >
          <option value="">Select an address</option>
          {shippingList.map((shipping) => (
            <option key={shipping.id} value={shipping.id}>
              {`${shipping.name}, ${shipping.address}, ${shipping.city}, ${shipping.state}, ${shipping.postalCode}`}
            </option>
          ))}
        </select>
      </div>

      <div className="w-6/12">
        <label
          htmlFor="name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Name:
        </label>
        <input
          name="name"
          type="text"
          id="name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={selectedAddress.name}
          onChange={handleFormChange}
        />
      </div>

      <div className="w-6/12">
        <label
          htmlFor="address"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Street Address:
        </label>
        <input
          value={selectedAddress.address}
          name="address"
          onChange={handleFormChange}
          type="text"
          id="address"
          className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="w-6/12">
        <label
          htmlFor="state"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          State:
        </label>
        <select
          name="state"
          value={selectedAddress.state}
          onChange={handleFormChange}
          id="state"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {LookUp.stateData.map((stateItem) => (
            <option key={stateItem.State} value={stateItem.State}>
              {stateItem.State}
            </option>
          ))}
        </select>
      </div>

      <div className="w-6/12">
        <label
          htmlFor="city"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          City:
        </label>
        <select
          name="city"
          value={selectedAddress.city}
          onChange={handleFormChange}
          id="city"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          {cities &&
            cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
        </select>
      </div>

      <div className="w-6/12">
        <label
          htmlFor="postalCode"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Pin Code:
        </label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={selectedAddress.postalCode}
          onChange={handleFormChange}
        />
      </div>
    </div>
  );
};

export default AddressCollection;
