import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "./home";
import Shop from "./shop";
import Login from "./login";
import { ShopProvider } from "./components/ShopContext";
import Order from "./order";
import VerifyOTP from "./verifyotp";
import Register from "./register";
import OrderSummary from "./ordersummary";
import Payment from "./payment";
import OrdersList from "./OrderList";
import AboutUs from "./aboutus";
import ForgotPassword from "./forgotpassword";
import ResetPassword from "./resetpassword";
import VerifyUser from "./verifyuser";
import ResendVerification from "./resendverification";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "",
        element: <Navigate to="home" />, // Redirect to "home"
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "shop",
        element: <Shop />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "resetpassword",
        element: <ResetPassword />,
      },
      {
        path: "verifyOtp",
        element: <VerifyOTP />,
      },
      {
        path: "verifyuser",
        element: <VerifyUser />,
      },
      {
        path: "ordersummary/:orderId/:mode?",
        element: <OrderSummary />,
      },
      {
        path: "payment/:orderId",
        element: <Payment />,
      },{
        path: "orderlist",
        element: <OrdersList />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },{
        path: "resendverification",
        element: <ResendVerification />,
      },
    ],
  }
  
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  
    <ShopProvider>      
      <RouterProvider router={router} />
    </ShopProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
