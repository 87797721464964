import { CartItem } from "../models/cart";

export class Helper{

    static getTotalAmount = (products : CartItem[]) => {
        if (!products) return 0;
        return products.reduce(
          (total, item) => total + item.quantity * item.product.price,
          0
        );
      };

    static readonly formatDateTime = () => {
        const date = new Date();
        
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
      
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      }
}