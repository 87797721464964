import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import ProfileService, { RegsiterForm } from "./services/profile";
import { useShop } from "./components/ShopContext";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// Static JSON for country codes and names
const countryCodes = [
  { code: "+1", name: "USA" },
  { code: "+91", name: "India" },
  // Add more countries as needed
];

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "", // Add countryCode to form data
    verifyPassword: "",
  });
  const query = useQuery();
  const token = query.get("token");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { setIsLoading } = useShop();
  const [passwordError, SetPasswordError] = useState<boolean>(false);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    SetPasswordError(formData.password !== formData.verifyPassword);
  }, [formData.password, formData.verifyPassword]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const proService: ProfileService = new ProfileService();
      const response = await proService.ResetPassword({
        token,
        password: formData.password,
      });

      if (response === "success") {
        toast.success("Password Reset Successful", {
          onClose: () => {
            navigate("/login");
          },
        });
      } else {
        toast.error(response, {
          onClose: () => {
            setIsLoading(false);
          },
        });
      }
    } catch (error: any) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Register</h2>
        {message && <p className="text-center text-red-500">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              minLength={6} // Example: Minimum 6 characters
              required
              className={`w-full p-2 border rounded ${
                passwordError ? "border-red-600" : "border-gray-300"
              }`}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Verify Password <span className="text-red-600">*</span>
            </label>
            <input
              type="password"
              name="verifyPassword"
              value={formData.verifyPassword}
              onChange={handleChange}
              minLength={6} // Example: Minimum 6 characters
              required
              className={`w-full p-2 border rounded ${
                passwordError ? "border-red-600" : "border-gray-300"
              }`}
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={passwordError}
              className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
