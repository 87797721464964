import { useCallback, useEffect, useState } from "react";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useShop } from "./components/ShopContext";
import { Order, OrderStatus } from "./models/order";
import "./payment.css";
import OrderService from "./services/order";
import PaymentService from "./services/payment";

export default function Payment() {
  const [Razorpay, isLoaded] = useRazorpay();
  const { orderId } = useParams();
  const [paymentStatus, setPaymentStatus] = useState("inprogress");
  const [paymentResponse, setPaymentResponse] = useState<{
    rOrderId: string;
    rPaymentId: string;
    rSignature: string;
  }>();
  const [orderDetails, setOrderDetails] = useState<Order>();
  const navigate = useNavigate();
  const { user } = useShop();
  const handlePayment = useCallback(
    async (orderDetail: Order) => {
      const paymentService: PaymentService = new PaymentService();
      let orderId: string|null = null;
      try {
        orderId = await paymentService.createPaymentOrder(
          orderDetail?.totalAmount!
        );
      } catch (error) {
        setPaymentStatus("Payment Failed");
      }
      if (orderId) {
        const options: RazorpayOptions = {
          key: "rzp_test_rBJ0hXoTUblKl6",
          amount: orderDetail?.totalAmount!.toString()!,
          currency: "INR",
          name: "Dhiya Pets",
          description: "Transaction for Dhiya Pets",
          image: "https://example.com/your_logo",
          order_id: orderId,
          handler: (res: any) => {
            setPaymentStatus("success");
            setPaymentResponse({
              rOrderId: res.razorpay_order_id,
              rPaymentId: res.razorpay_payment_id,
              rSignature: res.razorpay_signature,
            });
            const oService: OrderService = new OrderService();
            oService.updateOrder(
              orderDetail.id!,
              JSON.stringify(res),
              user?.id!
            );
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.phone,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
        rzpay.on("payment.failed", function (response: any) {
          setPaymentStatus("failed");
        });
      }
    },
    [Razorpay]
  );

  useEffect(() => {
    if (isLoaded) {
      const fetchOrder = async () => {
        const orderService: OrderService = new OrderService();
        const response = await orderService.getOrder(Number(orderId));
        if ("id" in response!) {
          if (response.status !== OrderStatus.NEW) {
            toast.error("Invalid order");
            navigate("/shop");
            return;
          }
          await setOrderDetails(response);
        }
        return response;
      };

      fetchOrder().then((res) => {
        if (res) handlePayment(res);
      });
    }
  }, [isLoaded]);
  /*
  useEffect(() => {
    const fetchOrder = async () => {
      const orderService: OrderService = new OrderService();
      const response = await orderService.getOrder(Number(orderId));
      if (response) {
        console.log(response);
        setOrderDetails(response);
      }
    };

    fetchOrder();
  }, []);*/

  return (
    <div>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          {paymentResponse && paymentStatus === "success" && (
            <>
              <h2 className="text-2xl font-bold text-green-600 mb-4">
                Payment Successful
              </h2>
              <p className="text-gray-700 mb-2">
                Order ID: {paymentResponse.rOrderId!}
              </p>
              <p className="text-gray-700 mb-2">
                Payment ID: {paymentResponse.rPaymentId!}
              </p>
              <button
                className="mt-6 hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/shop");
                }}
              >
                Go to Home
              </button>
            </>
          )}
          {paymentStatus === "failed" && (
            <>
              <h2 className="text-2xl font-bold text-red-600 mb-4">
                Payment Failed
              </h2>
              <p className="text-gray-700 mb-2">Please try again later.</p>
              <button
                className="mt-6 hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  handlePayment(orderDetails!);
                }}
              >
                Retry Payment
              </button>
              <button
                className="mt-6 hover:bg-black dark:bg-white dark:text-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/shop");
                }}
              >
                Go to Home
              </button>
            </>
          )}
          {paymentStatus === "inprogress" && (
            <div className="flex flex-col items-center">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Payment In Progress
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
