import React from "react";

// Import the logos
import ChihirosLogo from "./assets/chihiros-india.png";
import IntanLogo from "./assets/intan.png";
import ADALogo from "./assets/ada.webp";
import TetraBitsLogo from "./assets/tetra.png";
import LifeLogo from "./assets/api.png";
import IdidLogo from "./assets/idid.png";

const AboutUs = () => {
    return (
      <div className="bg-slate-700 p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-8">About Us</h1>
          
          <section className="mb-16">
            <h2 className="text-2xl font-semibold mb-4">Welcome to Dhiya Pets!</h2>
            <p className="text-lg leading-relaxed">
              At Dhiya Pets, we believe that pets are family. Our mission is to provide pet owners with the best products and services to ensure their aquatic and terrestrial friends live happy, healthy lives. From nutritious food and comfortable bedding to fun toys and stylish accessories, we have everything you need to keep your pets pampered and content.
            </p>
          </section>
  
          <section className="mb-16">
            <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
            <p className="text-lg leading-relaxed">
              Founded in 2018, Dhiya Pets started as a passionate hobby for aquarium enthusiasts. Over time, our love for aquariums and the desire to share this joy with others grew into a serious business. Today, we are a trusted source for all your pet needs, specializing in aquariums and aquatic supplies, while also catering to other pets such as dogs, cats, and small mammals. Our commitment to quality and our passion for animals have driven our growth and success.
            </p>
          </section>
  
          <section className="mb-16">
            <h2 className="text-2xl font-semibold mb-4">Our Brands</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-center">
                <img src={IdidLogo} alt="I-DID" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">I-DID</h3>
                  <p className="text-lg leading-relaxed">
                    Our own brand of aquarium tanks built with excellent craftmanships. 
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={ChihirosLogo} alt="Chihiros" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">Chihiros</h3>
                  <p className="text-lg leading-relaxed">
                    Chihiros is renowned for its high-quality aquarium lighting and equipment, helping to create vibrant and healthy aquatic environments.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={IntanLogo} alt="Intan" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">Intan</h3>
                  <p className="text-lg leading-relaxed">
                    Intan offers a wide range of aquarium accessories and supplies, ensuring that your aquatic pets have everything they need to thrive.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={ADALogo} alt="ADA" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">ADA</h3>
                  <p className="text-lg leading-relaxed">
                    ADA specializes in innovative and stylish aquarium products, combining functionality with aesthetic appeal to enhance your aquarium setup.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={TetraBitsLogo} alt="Tetra Bits" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">Tetra Bits</h3>
                  <p className="text-lg leading-relaxed">
                    Tetra Bits is known for its premium fish food, providing balanced nutrition to keep your aquatic pets healthy and active.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <img src={LifeLogo} alt="Life" className="w-20 h-20 object-contain mr-4" />
                <div className="border-l-2 border-gray-300 pl-4">
                  <h3 className="text-xl font-medium">Life</h3>
                  <p className="text-lg leading-relaxed">
                    Life offers a variety of pet products, from aquarium essentials to accessories for other pets, ensuring a holistic approach to pet care.
                  </p>
                </div>
              </div>
            </div>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-lg leading-relaxed">
              We'd love to hear from you! If you have any questions, suggestions, or feedback, please feel free to reach out to us.
            </p>
            <div className="mt-8">
              <h3 className="text-xl font-medium">Email:</h3>
              <p className="text-lg leading-relaxed">contact@dhiyapets.com</p>
            </div>
            <div className="mt-4">
              <h3 className="text-xl font-medium">Phone:</h3>
              <p className="text-lg leading-relaxed">+91 99945 89831</p>
            </div>
            <div className="mt-4">
              <h3 className="text-xl font-medium">Address:</h3>
              <p className="text-lg leading-relaxed">
              Door no A2/5, Senthil kumar nagar,villankuruchi road<br />
              Coimbatore-641035<br />
              Tamil Nadu, India
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  };
  
  export default AboutUs;