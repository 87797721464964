import React from "react";

interface DevModeWarningProps {
  onDismiss: () => void;
}

const DevModeWarning: React.FC<DevModeWarningProps> = ({ onDismiss }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-gray-900 h-3/5 w-3/5 bg-opacity-75 p-6 rounded-lg text-center relative text-gray-300" >
        <h2 className="text-2xl font-bold mb-4">Demo Mode Active</h2>
        <p className="mb-4">
          The site <strong>DhiyaPets</strong> is currently running in demo mode.
          Please be aware that some functionalities may not work as expected.
        </p>
        <div className="block">
          <strong>Do's:</strong>
          <ul className="list-disc list-inside">
            <li>Explore the site freely</li>
            <li>Test available features</li>
            <li>Provide feedback</li>
          </ul>
        </div>
        <div className="block mt-4">
          <strong>Don'ts:</strong>
          <ul className="list-disc list-inside">
            <li>Expect real data</li>
            <li>Make actual purchases</li>
            <li>Use personal information</li>
          </ul>
        </div>
        <button
          onClick={onDismiss}
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white bg-opacity-75 border border-white text-black rounded-lg hover:bg-opacity-50 transition-opacity"
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default DevModeWarning;
