import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProfileService from "./services/profile";
import { useShop } from "./components/ShopContext";
import ReCAPTCHA from "react-google-recaptcha";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const countryCodes = [
  { code: "+1", name: "USA" },
  { code: "+91", name: "India" },
];

interface FormData {
  email: string;
  password: string;
  name: string;
  address: string;
  phone: string;
  countryCode: string;
  verifyPassword: string;
}

interface TermsAndConditionsProps {
  onClose: () => void;
  onAgree: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onClose, onAgree }) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg">
      <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Privacy Policy</h3>
        <p>
          Your privacy is important to us. We collect personal information only
          to provide better services. We do not share your personal information
          with third parties without your consent, except as required by law.
        </p>
      </section>
      <section className="mb-4">
        <h3 className="text-xl font-semibold">Refund Policy</h3>
        <p>
          Refund requests will require video proof of unboxing. The refunded
          product, if not working, should not have any minor damages and should
          be in original packaging. Refunds will not be accepted if the product
          is working but does not meet customer requirements.
        </p>
      </section>
      <div className="flex justify-end space-x-4">
        <button
          onClick={onClose}
          className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-400"
        >
          Cancel
        </button>
        <button
          onClick={onAgree}
          className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-400"
        >
          Agree
        </button>
      </div>
    </div>
  </div>
);

const Register: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    name: "",
    address: "",
    phone: "",
    countryCode: "",
    verifyPassword: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const { setIsLoading } = useShop();
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [agreed, setAgreed] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setPasswordError(formData.password !== formData.verifyPassword);
  }, [formData.password, formData.verifyPassword]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA");
      return;
    }
    if (!agreed) {
      toast.error("You must agree to the terms and conditions");
      setShowTerms(true);
      return;
    }
    setIsLoading(true);
    try {
      const proService = new ProfileService();
      const response = await proService.Register(
        {
          ...formData,
          phone: formData.countryCode + formData.phone,
        },
        recaptchaToken
      );
      setIsLoading(false);
      if (response === "success") {
        setMessage("Registration successful!");
        toast.success("Registration Successful");
        setComplete(true);
      } else {
        setMessage("Registration failed");
        toast.error("Registration Failed");
      }
    } catch (error: any) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        {!complete ? (
          <>
            <h2 className="text-2xl font-bold text-center">Register</h2>
            {message && <p className="text-center text-red-500">{message}</p>}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div className="relative mt-1">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Verify Password <span className="text-red-600">*</span>
                </label>
                <input
                  type="password"
                  name="verifyPassword"
                  value={formData.verifyPassword}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
                {passwordError && (
                  <p className="text-red-500">Passwords do not match.</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Address <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Country Code <span className="text-red-600">*</span>
                </label>
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                >
                  <option value="" disabled>
                    Select Country Code
                  </option>
                  {countryCodes.map((code) => (
                    <option key={code.code} value={code.code}>
                      {code.name} ({code.code})
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Phone <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div className="mt-4">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                  onChange={setRecaptchaToken}
                />
              </div>
              <div className="flex items-center">
                <input
                  id="terms"
                  type="checkbox"
                  checked={agreed}
                  onChange={() => setShowTerms(true)}
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
                  I agree to the
                  <button
                    type="button"
                    onClick={() => setShowTerms(true)}
                    className="text-indigo-600 underline"
                  >
                    terms and conditions
                  </button>
                </label>
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-400"
              >
                Register
              </button>
            </form>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-2xl font-bold">Registration Successful!</h2>
            <button
              onClick={() => navigate("/login")}
              className="mt-4 px-4 py-2 text-white bg-green-500 rounded hover:bg-green-400"
            >
              Go to Login
            </button>
          </div>
        )}
      </div>
      {showTerms && (
        <TermsAndConditions
          onClose={() => setShowTerms(false)}
          onAgree={() => {
            setAgreed(true);
            setShowTerms(false);
          }}
        />
      )}
    </div>
  );
};

export default Register;
