import React from "react";

interface CartSkeletonProps {
  className: string;
}

const CartSkeleton: React.FC<CartSkeletonProps> = ({ className }) => {
  return (
    <div className={`w-64 h-96 bg-gray-200 animate-pulse dark:bg-gray-800 shadow-md rounded-lg overflow-hidden cursor-pointer ${className}`}>
      <div className="w-full h-1/2 bg-gray-400"></div>
      <div className="p-4 h-1/2 flex flex-col justify-between">
        <div className="h-6 bg-gray-400 rounded mb-3"></div>
        <div className="flex items-center">
          <div className="h-5 w-5 bg-gray-400 rounded mr-1"></div>
          <div className="h-5 w-5 bg-gray-400 rounded mr-1"></div>
          <div className="h-5 w-5 bg-gray-400 rounded mr-1"></div>
          <div className="h-5 w-5 bg-gray-400 rounded mr-1"></div>
          <div className="h-5 w-5 bg-gray-400 rounded mr-1"></div>
          <span className="ml-3 mr-2 rounded bg-gray-400 px-2.5 py-0.5 w-6"></span>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="h-6 w-12 bg-gray-400 rounded"></div>
          <div className="h-8 w-32 bg-gray-400 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default CartSkeleton;