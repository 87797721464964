import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddToCartRequest, CartItem } from "../models/cart";
import CartService from "../services/cart";
import LoginService from "../services/login";
import { BasicUser, UserResponse } from "../services/profile";
import { getCookie, removeCookie } from "../util/cookie";

interface ShopContextType {
  cartItems: CartItem[] | null;
  setCartItems: React.Dispatch<React.SetStateAction<CartItem[] | null>>;
  user: BasicUser | null;
  setUser: React.Dispatch<React.SetStateAction<BasicUser | null>>;
  UpdateToCart: (productId: number, quantity: number) => void;
  isDevMode: boolean;
  isLoading: boolean;
  setIsLoading:React.Dispatch<React.SetStateAction<boolean>>;
}

const ShopContext = createContext<ShopContextType | undefined>(undefined);
const isDevMode = process.env.NODE_ENV === "development";
export const ShopProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cartItems, setCartItems] = useState<CartItem[] | null>([]);
  const [user, setUser] = useState<BasicUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      let himaToken = getCookie("himaToken");
      if (himaToken && himaToken !== "") {
        const loginService = new LoginService();
        try {
          const response: UserResponse = await loginService.getUserDetails(
            himaToken
          );
          if (response.success) {
            setUser(response.userDetails);
            const cartService: CartService = new CartService();
            try {
              const userCartItems = await cartService.GetCart(
                response.userDetails.id.toString()
              );

              if (userCartItems && !("error" in userCartItems)) {
                setCartItems(userCartItems.items);
              } else {
                console.error(
                  "Failed to retrieve cart items:",
                  userCartItems?.error
                );
                // Handle the error accordingly, e.g., show an error message to the user
              }
            } catch (error) {
              console.error("Unexpected error retrieving cart items:", error);
              // Handle unexpected errors accordingly
            }
          } else {
            removeCookie("himaToken");
          }
        } catch (error) {
          removeCookie("himaToken");
          console.error("user login failed");
        }
      }
    };

    fetchUserDetails();
  }, []);

  const UpdateToCart = async (productId: number, quantity: number) => {
    let userId: number = 0;

    if (!user || !user.id || user.id < 1) {
      toast.error("Please login to add to cart");
      window.location.href = `${process.env.REACT_APP_OWN_DOMAIN}/login`;
    } else {
      userId = user?.id;

      const cartService: CartService = new CartService();
      const add2CartRequest: AddToCartRequest = { productId, quantity, userId };

      const response = await cartService.addCartItem(add2CartRequest);
      if ("error" in response) {
        toast.error(response.error);
        console.error("Failed to add item to cart:", response.error);
        // Handle the error accordingly, e.g., show an error message to the user
      } else {
        // Assuming you have a state management method like setCartItems
        setCartItems(response.cart.items);
        // Optionally, you can also handle any success messages or additional logic here
      }
    }
  };

  return (
    <ShopContext.Provider
      value={{
        isDevMode,
        cartItems,
        setCartItems,
        user,
        setUser,
        UpdateToCart,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
