import React, { useState } from "react";
import LoginService from "./services/login";
import { setCookie } from "./util/cookie";
import { useNavigate } from "react-router-dom";
import { useShop } from "./components/ShopContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const loginService = new LoginService();
  const { setIsLoading } = useShop();
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async () => {
    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA");
      return;
    }

    let isLoginSuccess = false;
    try {
      setIsLoading(true);

      await loginService.login(username, password, recaptchaToken).then((res) => {
        console.log(res);
        if (res.success) {
          isLoginSuccess = true;
          setCookie("himaToken", res.sessionToken, 10);
          setCookie("himaJWT", res.jwt, 7);
        }
      });

      if (isLoginSuccess) {
        window.location.replace(`${process.env.REACT_APP_OWN_DOMAIN}/shop`);
      } else {
        toast.error("Login failed", {
          onClose: () => {
            setIsLoading(false);
          },
        });
      }
    } catch (err) {
      console.error(err);
      toast.error("Login failed", {
        onClose: () => {
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={require("./assets/logo_nav.png")}
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  href="/forgotpassword"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2 relative">
      <input
        id="password"
        name="password"
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLogin();
          }
        }}
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        {showPassword ? (
          <EyeIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
        )}
      </button>
    </div>
          </div>

          <div className="mt-4">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
            onChange={(token: string | null) => setRecaptchaToken(token)}
          />
            <button
              type="submit"
              onClick={handleLogin}
              className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
            <div className="flex">
            <a
              href="#"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                navigate("/resendverification");
              }}
              className="flex w-full justify-end mt-6 underline text-blue-600"
            >
              Resend Verification Mail
            </a>
            <a
              href="#"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                navigate("/register");
              }}
              className="flex w-full justify-end mt-6 underline text-blue-600"
            >
              Sign up
            </a>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}
