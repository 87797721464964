// fetchInterceptor.ts

import CONSTANTS from "../const";
import { getCookie, removeCookie, setCookie } from "../util/cookie";





const refreshToken = async (): Promise<string | null> => {
  const refresh_token = getCookie("himaToken");

  if (!refresh_token) {
    removeCookie("himaToken");
    removeCookie("himaJWT");
    return null;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_APILINK}/api/users/refreshToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',        
      },
      credentials:"include",
      body: JSON.stringify({ "refreshToken":refresh_token }),
    });

    if (!response.ok) {
        removeCookie("himaToken");
        removeCookie("himaJWT");
      return null;
    }

    const data = await response.json();
    const newAccessToken = data.jwt;
    setCookie("himaJWT",newAccessToken,1)

    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh token', error);
    removeCookie("himaToken");
    removeCookie("himaJWT");
    return null;
  }
};

const fetchWithAuth = async (url: string, options: RequestInit = {}): Promise<Response> => {
  let accessToken:string | null | undefined = getCookie("himaJWT");

  if (!accessToken) {
    accessToken = await refreshToken();
    if (!accessToken) {
      throw new Error('Unable to refresh token');
    }
  }

  const authOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(url, authOptions);

  if (response.status === 401) {
    accessToken = await refreshToken();
    if (!accessToken) {
      throw new Error('Unable to refresh token');
    }

    const retryAuthOptions: RequestInit = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return fetch(url, retryAuthOptions);
  }

  return response;
};

export default fetchWithAuth;
