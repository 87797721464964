interface TermsAndConditionsProps {
    onClose: () => void;
    onAgree: () => void;
  }
  
  const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onClose, onAgree }) => (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
        <section className="mb-4">
          <h3 className="text-xl font-semibold">Privacy Policy</h3>
          <p>
            Your privacy is important to us. We collect personal information only
            to provide better services. We do not share your personal information
            with third parties without your consent, except as required by law.
          </p>
        </section>
        <section className="mb-4">
          <h3 className="text-xl font-semibold">Refund Policy</h3>
          <p>
            Refund requests will require video proof of unboxing. The refunded
            product, if not working, should not have any minor damages and should
            be in original packaging. Refunds will not be accepted if the product
            is working but does not meet customer requirements.
          </p>
        </section>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-400"
          >
            Cancel
          </button>
          <button
            onClick={onAgree}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-400"
          >
            Agree
          </button>
        </div>
      </div>
    </div>
  );

  export default TermsAndConditions;