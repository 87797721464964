"use server";

import { json } from "react-router-dom";
import CONSTANTS from "../const";
import { Order, Shipping } from "../models/order";
import { getCookie } from "../util/cookie";
import fetchWithAuth from "./fetchInterceptors";

export interface ShippingResponse {
  serviceAvailable: boolean;
  rate: number;
  etd: string;
}

class OrderService {
  private baseURL: string;

  constructor() {
    this.baseURL = `${process.env.REACT_APP_APILINK}/api`;
  }

  async getOrder(orderId: number): Promise<Order | void> {
    const response = await fetchWithAuth(`${this.baseURL}/orders/${orderId}`, {
      method: "GET",
      credentials: "include",
    });
    if (response.status === 200) {
      const orderDetail = await response.json();
      return orderDetail;
    }
    return;
  }

  async getUserOrders(): Promise<Order[]> {
    const response = await fetchWithAuth(`${this.baseURL}/users/orders`, {
      method: "GET",
      credentials: "include",
    });
    if (response.status === 200) {
      const orderDetails = await response.json();
      return orderDetails;
    }
    return [];
  }

  async cancelUserOrders(orderId:number): Promise<{order?:Order|null,message:string} > {
    const response = await fetchWithAuth(`${this.baseURL}/orders/${orderId}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify({status:'CANCELLED'})
    });
    if (response.status === 200) {
      const orderDetails = await response.json();
      return orderDetails;
    }
    return {message:"failure"};
  }

  async createOrder(
    userId: number,
    shipping: Shipping,
    billing: Shipping,
    shippingPrice: number,
    etd: string
  ): Promise<Order | string> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ userId, shipping, billing, shippingPrice, etd }),
      });
      if (response.status === 201) {
        const newOrderDetails = await response.json();
        return newOrderDetails;
      } else {
        return "fail";
      }
    } catch (error: any) {
      console.error(error);;
      return "fail";
    }
  }

  async updateOrder(
    orderId: number,
    paymentReference: string,
    userId:number
  ): Promise<string> {
    try {
      const response = await fetchWithAuth(`${this.baseURL}/orders/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ status:"PENDING", paymentReference,userId }),
      });
      if (response.status === 201) {
        const status = await response.json();
        return status;
      } else {
        return "fail";
      }
    } catch (error: any) {
      console.error(error);;
      return "fail";
    }
  }

  async downloadBill(orderId: number):Promise<any>{
    return await fetchWithAuth(
      `${this.baseURL}/orders/generateBill`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body:JSON.stringify({orderId})
      }
    );
  }

  async getShippingRate(
    dPCode: string,
    pPCode: string,
    weight: number
  ): Promise<ShippingResponse> {
    try {
      const response = await fetchWithAuth(
        `${this.baseURL}/shipping?weight=${weight}&deliveryCode=${dPCode}&pickupCode=${pPCode}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }

      const data: ShippingResponse = await response.json();
      return data;
    } catch (error: any) {
      console.error(error);;
      throw error;
    }
  }

}

export default OrderService;
