import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Footer } from "flowbite-react";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./assets/logo_nav.png";
import Cart from "./components/cart";
import DevModeWarning from "./components/DevModeWarning";
import { useShop } from "./components/ShopContext";
import Spinner from "./components/Spinner";
import { Product } from "./models/common";
import GeneralService from "./services/general";
import { removeCookie } from "./util/cookie";

function App() {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const { cartItems, user, UpdateToCart, isDevMode, isLoading } = useShop();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Product[]>();
  const [loading, setLoading] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showDevModeWarning, setShowDevModeWarning] = useState(isDevMode);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fetchResults = async (searchQuery: string) => {
    try {
      setLoading(true);
      const gService: GeneralService = new GeneralService();
      const response: Product[] = await gService.searchProducts(searchQuery);
      setResults(response);
      setShowSearchResults(response && response.length > 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setLoading(false);
    }
  };

  const debouncedFetchResults = debounce(fetchResults, 300);

  useEffect(() => {
    if (query.trim() !== "") {
      debouncedFetchResults(query);
    } else {
      setResults([]);
    }
  }, [query]);

  const handleLogout = (e: any) => {
    e.preventDefault();
    removeCookie("himaToken");
    navigate("/login");
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    navigate("/login");
  };

  const getTotalQuantity = () => {
    if (!cartItems) return 0;
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const onDismiss = () => {
    setShowDevModeWarning(false);
  };

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>
          Dhiya Pets - Your One-Stop Shop for Aquariums and Pet Supplies
        </title>
        <meta
          name="description"
          content="Discover a wide range of aquariums, pet accessories, and supplies at Dhiya Pets. Shop from top brands like Chihiros, Intan, ADA, Tetra Bits, and Life."
        />
        <meta
          name="keywords"
          content="aquarium, pet shop, pet accessories, fish tanks, Chihiros, Intan, ADA, Tetra Bits, Life, aquarium plants,"
        />
        <meta
          property="og:title"
          content="Dhiya Pets - Your One-Stop Shop for Aquariums and Pet Supplies"
        />
        <meta
          property="og:description"
          content="Explore premium aquariums and pet accessories at Dhiya Pets. Quality products for all your pet needs."
        />
        <meta property="og:image" content="URL_to_image" />
        <meta property="og:url" content="https://dhiyapets.com" />
        <link rel="canonical" href="https://www.dhiyapets.com" />
      </Helmet>
      {isLoading && <Spinner />}
      {isDevMode && showDevModeWarning && (
        <DevModeWarning onDismiss={onDismiss} />
      )}
      <ToastContainer />
      <div className="bg-cyan-500 h-15 md:h-20 flex justify-between items-center md:px-4">
        <div
          id="logo"
          className="cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <img src={logo} alt="Logo" className="h-10 md:h-16 object-contain" />
        </div>
        <div className="relative w-full mx-4">
          <div
            id="search"
            className="bg-slate-300 border rounded-lg dark:border-gray-700 focus-within:border-primary focus-within:ring focus-within:ring-primary dark:focus-within:border-primary focus-within:ring-opacity-20 flex-grow"
          >
            <div className="flex items-center rounded-lg">
              <input
                type="search"
                name="query"
                placeholder="Search Products"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="h-7 md:h-10 md:px-4 text-gray-700 placeholder-gray-400 bg-white border-none appearance-none dark:text-gray-200 focus:outline-none focus:placeholder-transparent focus:ring-0 w-full text-xs"
              />
              <button
                type="submit"
                className="flex items-center justify-center p-2 text-white transition-colors duration-300 transform rounded-lg bg-primary hover:bg-primary/70 focus:outline-none focus:bg-primary/70"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-3 h-3 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          {loading && <Spinner />}
          {showSearchResults && (
            <div
            role="presentation"
              className="absolute w-full p-2 mt-2 bg-white border rounded-lg shadow-lg"
              onMouseLeave={() => setShowSearchResults(false)}
            >
              {results?.map((product) => (
                <div
                  key={product.id}
                  className="flex items-center p-2 border-b hover:bg-gray-100"
                >
                  <img
                    src={`${process.env.REACT_APP_R2_PUB_URL}/${product.images[0]}`}
                    alt={product.name}
                    className="w-12 h-12 mr-4"
                  />
                  <div className="flex-grow">
                    <h3 className="text-sm font-medium">{product.name}</h3>
                  </div>
                  <div className="text-sm font-medium text-gray-900">
                    ${product.price}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div id="cart" className="relative">
          <button className="focus:outline-none" onClick={() => setCartOpen(true)}>
            <svg
              className="w-4 h-4 md:w-8 md:h-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-1.4 7h11.2l-1.4-7M5 21a1 1 0 100-2 1 1 0 000 2zm14 0a1 1 0 100-2 1 1 0 000 2z"
              />
            </svg>
            {cartItems && cartItems.length > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-yellow-400 rounded-full">
                {getTotalQuantity()}
              </span>
            )}
          </button>
        </div>
        <div
          id="profile"
          className="relative mx-4 flex"
          onMouseEnter={() => setDropdownVisible(true)}
          onMouseLeave={() => setDropdownVisible(false)}
        >
          <button className="focus:outline-none">
            <svg
              className="w-4 h-4 md:w-8 md:h-8 text-white"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            </svg>
          </button>
          {dropdownVisible && (
            <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-20">
              {user && (
                <p className="text-blue-600 text-sm px-4 py-2">
                  Welcome, {user.name}
                </p>
              )}
              {user ? (
                <a
                  href="/"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Logout
                </a>
              ) : (
                <a
                  href="/"
                  onClick={handleLogin}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Login
                </a>
              )}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/orderlist");
                }}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Your Orders
              </a>
            </div>
          )}
        </div>
      </div>
      <nav className="bg-gray-900 text-white border-b border-gray-200">
        <div className="container mx-auto block text-center md:flex md:justify-between  items-center p-4">
          <div className="flex justify-between">
            <div className="text-xl font-bold w-full">Dhiya Pets</div>
            <div className="block lg:hidden">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? (
                  <XMarkIcon className="h-6 w-6 text-white" />
                ) : (
                  <Bars3Icon className="h-6 w-6 text-white" />
                )}
              </button>
            </div>
          </div>

          <div
            className={`lg:flex lg:space-x-4 ${isMenuOpen ? "block" : "hidden"}`}
          >
            <a
              href="/home"
              className="block mt-4 lg:mt-0 bg-gray-800 text-white py-2 px-4 rounded-md text-center hover:bg-gray-700 transition-colors duration-300"
            >
              Home
            </a>
            <a
              href="/shop"
              className="block mt-4 lg:mt-0 bg-gray-800 text-white py-2 px-4 rounded-md text-center hover:bg-gray-700 transition-colors duration-300"
            >
              Shop
            </a>
            <a
              href="/aboutus"
              className="block mt-4 lg:mt-0 bg-gray-800 text-white py-2 px-4 rounded-md text-center hover:bg-gray-700 transition-colors duration-300"
            >
              About Us
            </a>
            {!user && (
              <>
                <a
                  href="/register"
                  className="block mt-4 lg:mt-0 bg-gray-800 text-white py-2 px-4 rounded-md text-center hover:bg-gray-700 transition-colors duration-300"
                >
                  Register
                </a>
                <a
                  href="/login"
                  className="block mt-4 lg:mt-0 bg-gray-800 text-white py-2 px-4 rounded-md text-center hover:bg-gray-700 transition-colors duration-300"
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </nav>

      <div className="container mx-auto">
        <Outlet />
      </div>
      <Cart
        open={cartOpen}
        setOpen={setCartOpen}
        products={cartItems}
        updateToCart={UpdateToCart}
        navigate={navigate}
      />
      <div>
        <Footer container bgDark className="mt-4">
          <Footer.Copyright href="#" by="dhiyapets.com" year={2022} />
          <Footer.LinkGroup>
            <Footer.Link href="#">About</Footer.Link>
            <Footer.Link href="#">Privacy Policy</Footer.Link>
            <Footer.Link href="#">Licensing</Footer.Link>
            <Footer.Link href="#">Contact</Footer.Link>
          </Footer.LinkGroup>
        </Footer>
      </div>
    </div>
  );
}

export default App;
